<template>
  <section class="bets" data-t="bets-modal-ztgr">
    <header class="bets-header">
      <StTabs
        v-model="selectedHistoryType"
        :tabs="typeTabs"
        type="mobile-l"
        is-icons-disabled
      />
      <button class="close-button" @click="close">
        <StIcon name="cross-large" :size="20" />
      </button>
    </header>
    <div class="tabs-bg">
      <div v-if="selectedHistoryType === 'sport'" class="sport-type-tabs">
        <StTabs
          v-model="selectedBetType"
          :tabs="betTypes"
          type="mobile-m"
          :counter="{ bgColor: 'orange' }"
        />
      </div>
    </div>
    <div class="bets-list">
      <div class="wrapper">
        <MBetHistory
          v-if="selectedHistoryType === 'sport'"
          :bet-type="selectedBetType"
        />
        <CasinoBetsHistory v-else mobile />
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import MBetHistory from '@st/coupon/components/BetHistory/MBetHistory.vue'
import { useBetHistory } from './useBetHistory'

const { selectedHistoryType, selectedBetType, betTypes, typeTabs } =
  useBetHistory()

const activeBetsStore = useActiveBetsStore()
const { betCounter, isReady } = storeToRefs(activeBetsStore)
const { isCasinoPage } = useMatchLinkToRoute()

async function pickDefaultTab() {
  if (isCasinoPage.value) {
    selectedHistoryType.value = 'casino'
    return
  }

  /* дожидаемся готовности стора */
  await until(isReady).toBe(true)

  if (!betCounter.value) {
    selectedBetType.value = 'processed'
  }
}

onMounted(async () => {
  pickDefaultTab()
})

const router = useRouter()
function close() {
  router.replace({ query: {} })
}
</script>

<style scoped>
.bets {
  height: 100%;
}

.bets-header {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);

  width: 100%;
  padding: var(--spacing-100) var(--spacing-050) var(--spacing-100)
    var(--spacing-100);

  background-color: var(--background-base);
}

.title {
  margin: var(--spacing-025) 0;
  font: var(--mobile-title-1-semibold);
}

.bets-list {
  position: relative;
  z-index: 1;

  overflow-y: auto;
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: calc(100dvh - 198px);

  background: var(--background-base);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}

.tabs-bg {
  position: relative;
  padding: var(--spacing-050);
  background-color: var(--background-base);
  border-top: var(--border-width-light, 0.5px) solid var(--border-secondary);
}

.sport-type-tabs {
  padding: var(--spacing-050) var(--spacing-150);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 0 var(--spacing-200);
  padding-bottom: var(--spacing-200);
}

.close-button {
  all: unset;

  position: absolute;
  top: 4px;
  right: 4px;

  padding: var(--spacing-125);

  line-height: 0;
}
</style>
